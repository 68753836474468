import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import {useHistory} from 'react-router-dom';
import { Grid, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import UserEditForm from './UserEditForm';
import CustomAlert from '../common/CustomAlert';
import CustomCardHeader from '../common/CustomCardHeader';
import ProgressFormCard from '../common/ProgressFormCard';
import CircularProgressButton from '../common/CircularProgressButton';
import SuccessMessageComponent from '../common/SuccessMessageComponent';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../common/styled';
import routes from '../../util/routes';
import {isEmptyObject} from '../../util/helpers';
import useFolder from '../../api/useFolder';
import HelpSystemContext from '../../context/HelpSystemContext';
import { red } from '@mui/material/colors';

const useStyles = makeStyles(function useTheme(theme) {
    // noinspection MagicNumberJS
    return {
        root: {
            padding: theme.spacing(3),
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
            alignSelf: 'center',
        },
    }
});

// noinspection FunctionNamingConventionJS
function UserCreateComponent(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const [user, setUser] = useState(props.user);
    const {folder: propsHomeFolder} = useFolder({params: {folderId: user.homeFolderId}});
    const [homeFolder, setHomeFolder] = useState(propsHomeFolder);
    const [showDisableEnableMessage, setShowDisableEnableMessage] = useState(false);

    console.log('USER', user);

    // Set user when the props are loaded.
    useEffect(() => {
        setUser(props.user)
    }, [props.user]);

    // Set home folder when the folder is loaded.
    useEffect(() => {
        setHomeFolder(propsHomeFolder);
    }, [propsHomeFolder]);

    const handleSubmit = async function handleUpdateUser(event, data) {
        event.preventDefault();
        let response = await props.saveUser(data);
        console.log('DATA', data);
        setUser({...user, ...data, folderPermissions: response.data.folderPermissions});
    };

    const disableUser = async function disableUser(event) {
        try {
            await handleSubmit(event, {enabled: false});
            setUser({...user, enabled: false});
            setShowDisableEnableMessage(true);
        } catch (error) {
            console.error(error);
            toast.notify(
                ({onClose}) => <CustomAlert type='error' message='Could not disable this user!' onClose={onClose}/>);
        }
    };

    const enabledUser = async function enabledUser(event) {
        try {
            await handleSubmit(event, {enabled: true});
            setUser({...user, enabled: true});
            setShowDisableEnableMessage(true);
        } catch (error) {
            console.error(error);
            toast.notify(
                ({onClose}) => <CustomAlert type='error' message='Could not enabled this user!' onClose={onClose}/>);
        }
    };

    return (
        <HelpSystemContext.Consumer>
            {open =>
                <div className={classes.root}>
                    <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
                        <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.users.path)}>
                            <StyledBreadcrumbIcon theme={theme}/>
                            Back to users
                        </StyledBreadcrumbLink>
                    </StyledBreadcrumbs>
                    <ProgressFormCard
                        loadingContent={props.loadingUser}
                        header={
                            <Grid container alignContent='center' justifyContent='center'>
                                <Grid item md={open ? 12 : 10} style={{width: '100%'}}>
                                    <CustomCardHeader style={{paddingRight: '8px', paddingLeft: '8px'}}
                                                      title={routes.updateUser.pageTitle}
                                                      titleAlign='center'
                                                      action={<Grid container justifyContent='center'>
                                                          <SuccessMessageComponent show={showDisableEnableMessage}
                                                                                   setShow={setShowDisableEnableMessage}
                                                                                   mr={2}/>
                                                          {!isEmptyObject(user) && user.enabled &&
                                                          <CircularProgressButton mt={0} size='small'
                                                                                  label='Disable User'
                                                                                  buttonTextTransform='none'
                                                                                  onClick={disableUser}/>
                                                          }
                                                          {!isEmptyObject(user) && !user.enabled &&
                                                          <CircularProgressButton style={{backgroundColor: red['800']}}
                                                                                  mt={0}
                                                                                  size='small' label='Enable User'
                                                                                  buttonTextTransform='none'
                                                                                  onClick={enabledUser}/>
                                                          }
                                                      </Grid>}
                                    />
                                </Grid>
                            </Grid>
                        }
                        content={
                            <UserEditForm id={user.id} username={user.username} notes={user.notes}
                                          homeFolder={homeFolder} folderPermissions={user.folderPermissions}
                                          publicKeys={user.publicKeys} allowedIps={user.allowedIps}
                                          uid={user.uid} gid={user.gid} ipAllowListEnabled={user.ipAllowListEnabled}
                                          isPasswordSet={user.isPasswordSet} usesPassword={user.usesPassword}
                                          updatePassword={handleSubmit} handleSubmit={handleSubmit}
                            />
                        }
                    />
                </div>
            }
        </HelpSystemContext.Consumer>
    );
}

export default UserCreateComponent;
