import React, {useEffect, useState} from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy as copyIcon} from '@fortawesome/free-regular-svg-icons';
import {Publish} from '@mui/icons-material';
import {Button, Grid, IconButton, InputAdornment, useTheme} from '@mui/material';
import CustomAlert from '../common/CustomAlert';
import PageSection from '../common/PageSection';
import FormControlInput from '../common/FormControlInput';
import CircularProgressButton from '../common/CircularProgressButton';
import {isEmpty} from '../../util/helpers';
import TestConnectionResultTable from './TestConnectionResultTable';
import api_routes from '../../util/api_routes';
import {GCP} from '../../util/cloud_providers';
import FormControlRadioGroup from '../common/FormControlRadioGroup';
import HelpSystemContext from '../../context/HelpSystemContext';

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing(2)};
`;

// noinspection FunctionNamingConventionJS
function GoogleCloudConnectionForm(props) {

    const theme = useTheme();
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    useEffect(() => {
        setLoadingSubmit(false);
    }, [props.errors]);

    const handleUploadKey = (event) => {
        let inputFile = event?.target.files[0];
        let reader = new FileReader();
        reader.onload = function readOnLoad(e) {
            let contents = e.target.result;
            props.setJwtToken({[event.target.name]: Buffer.from(contents).toString('base64'), jsonKeyFilename: inputFile.name});
        };
        reader.readAsBinaryString(inputFile);
    };

    const handleSubmit = async function handleFolderSubmit(event) {
        setLoadingSubmit(true);
        await props.handleSubmit(event);
    };

    const copyUrl = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            toast.notify(({onClose}) => <CustomAlert type='success' message='Url copied!' onClose={onClose}/>);
        });
    };

    const testConnectivity = async () => {
        return await axios.post(`${api_routes.cloudConnectionTest.endpoint}`, {
            basePrefix: props.basePrefix,
            jwtToken: props.jwtToken,
            useInstanceCredentials: props.useInstanceCredentials,
            cloudProvider: GCP,
        });
    }

    return (
        <HelpSystemContext.Consumer>
            {open =>
                <form onSubmit={handleSubmit} noValidate>

                    <Grid container justifyContent='center'>
                        <Grid container item md={open ? 12 : 8}>
                            <FormControlInput value={props.name} width='100' label='Connection Name' name='name'
                                              placeholder='Enter unique name' onChange={props.setName} required
                                              helperText='Name used to identify this cloud connection.'
                                              errorMessage={props.errors.name}
                            />
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <FormControlInput value={props.notes} width='100' label='Cloud Connection Notes'
                                              name='notes' multiline onChange={props.setNotes}
                                              inputProps={{maxLength: 1000}} showCount
                                              placeholder='Enter notes about the cloud connection (optional)'
                                              errorMessage={props.errors.notes}
                            />
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <FormControlInput value={props.basePrefix} width='100' label='GCS Bucket URI' name='basePrefix'
                                              required
                                              autoComplete="off"
                                              onChange={props.setBasePrefix} placeholder='Enter GsUtil URI for GCP Bucket'
                                              helperText='Ex: gs://bucket-name'
                                              errorMessage={props.errors.basePrefix}
                                              endAdornment={<InputAdornment position='end'>
                                                  <IconButton
                                                      onClick={function handleCopyUrl(event) {
                                                          event.stopPropagation()
                                                          copyUrl(props.basePrefix)
                                                      }}
                                                      size="large">
                                                      <FontAwesomeIcon icon={copyIcon} color={theme.palette.primary.main}
                                                                       size={"xs"}/>
                                                  </IconButton>
                                              </InputAdornment>}
                            />
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>

                            <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                                         subtitle='Credentials used to access this cloud connection.' subtitleVariant='body2'/>
                            <FormControlRadioGroup name='useInstanceCredentials' value={props.useInstanceCredentials}
                                                   onChange={props.setInstanceCredentials}
                                                   options={[
                                                       ...(props.showInstanceCredentialsOption ?
                                                           [{value: true, label: 'Use Instance\'s Service Account'}] :
                                                           []),
                                                       {value: false, label: 'Use JSON Key File'}
                                                   ]}
                            />
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <FormControlInput width='100' label='Upload Service Account JSON Key File'
                                              uncontrolled
                                              helpLink={{
                                                  href: 'http://www.sftpgateway.com/google-service-account-key-file.html',
                                                  target: '_blank',
                                                  text: 'Where do I find the Service Account JSON Key File?'
                                              }}
                                              disabled={props.useInstanceCredentials}
                                              required={!props.useInstanceCredentials}
                                              name='jwtToken' onChange={handleUploadKey}
                                              errorMessage={props.errors.jwtToken}
                                              type='file'
                                              inputProps={{accept: '.json'}}
                                              startAdornment={
                                                  <InputAdornment position="start">
                                                      <Publish/>
                                                  </InputAdornment>
                                              }
                            />
                        </Grid>
                        {!isEmpty(props.connectivity) &&
                        <Grid container item md={open ? 12 : 8}>
                            <TestConnectionResultTable connectivity={props.connectivity}
                                                       disabled={(!props.useInstanceCredentials && isEmpty(props.jwtToken)) ||
                                                       (props.useInstanceCredentials && isEmpty(props.basePrefix))} test={testConnectivity}/>
                        </Grid>
                        }
                        <Grid container item justifyContent='flex-end' md={open ? 12 : 8}>
                            <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                                    label='Save' mt={2} inProgress={loadingSubmit}/>
                            <StyledButton onClick={props.handleCancel} disableElevation size='small' type='reset'
                                          theme={theme}>Cancel</StyledButton>
                        </Grid>
                    </Grid>
                </form>
            }
        </HelpSystemContext.Consumer>
    );
}

GoogleCloudConnectionForm.propTypes = {
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    name: PropTypes.string,
    setName: PropTypes.func,
    notes: PropTypes.string,
    setNotes: PropTypes.func,
    basePrefix: PropTypes.string,
    setBasePrefix: PropTypes.func,
    useInstanceCredentials: PropTypes.bool,
    setInstanceCredentials: PropTypes.func,
    jwtToken: PropTypes.string,
    setJwtToken: PropTypes.func,
    errors: PropTypes.object,
    showInstanceCredentialsOption: PropTypes.bool,
};
GoogleCloudConnectionForm.defaultProps = {};
export default GoogleCloudConnectionForm;
