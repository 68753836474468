import React, {useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import { Card, CardContent, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import routes from '../../util/routes';
import {convertErrorsToObject} from '../../util/errorHandler';
import CustomAlert from '../common/CustomAlert';
import CustomCardHeader from '../common/CustomCardHeader';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../common/styled';
import AwsCloudConnectionForm from './AwsCloudConnectionForm';
import AzureCloudConnectionForm from './AzureCloudConnectionForm';
import {isEmpty} from '../../util/helpers';
import GoogleCloudConnectionForm from './GoogleCloudConnectionForm';
import {AWS, AZURE, GCP} from '../../util/cloud_providers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const {cloudProvider} = window._env_ || {};
const CLOUD_PROVIDER = cloudProvider || process.env.REACT_APP_CLOUD_PROVIDER;

const initCloudConnection = (provider, defaultName) => {
  return {
    name: defaultName,
    notes: '',
    basePrefix: '',
    ...(provider === AWS && {
      cloudProvider: 'aws',
      encryptionType: 'SSE_S3',
      encryptionId: '',
      region: null,
      useInstanceCredentials: CLOUD_PROVIDER === AWS,
      accessKeyId: '',
      accessSecret: '',
    }),
    ...(provider === AZURE && {
      cloudProvider: 'azure',
      useInstanceCredentials: false,
      connectionString: '',
    }),
  ...(provider === GCP && {
      cloudProvider: 'gcp',
      useInstanceCredentials: false,
      jwtToken: '',
      jsonKeyFilename: '',
    })
  };
};

const initCloudConnectivity = {
    canList: false,
    canRead: false,
    canWrite: false,
}

// noinspection FunctionNamingConventionJS
function CloudConnectionCreateComponent(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const provider = (!isEmpty(new URLSearchParams(location.search).get('provider')))
      ? new URLSearchParams(location.search).get('provider')
      : 'aws';
  const defaultName = (!isEmpty(new URLSearchParams(location.search).get('name')))
      ? new URLSearchParams(location.search).get('name')
      : '';
  const providerTitle = () => {
    switch (provider) {
        case AWS:
            return 'AWS';

        case AZURE:
            return 'Azure';

        case GCP:
            return 'Google';
        default: {
            console.error("Unsupported Cloud Provider")
            return 'Unsupported'
        }
    }
  }

  const [isBlocking, setIsBlocking] = useState(false);
  const [cloudConnection, setCloudConnection] = useState(initCloudConnection(provider, defaultName));
  const [errors, setErrors] = useState(initCloudConnection);

  const resetErrors = function resetCloudConnectionErrors() {
    setErrors(initCloudConnection);
  };

  const handleTextChange = function handleTextChangeOnCloudConnectionForm(event) {
    setIsBlocking(true);
    setCloudConnection({...cloudConnection, [event.target.name]: event.target.value});
  };

    const setBasePrefix = (value) => {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, basePrefix: value});
    };

    const handleChangeProp = function handleChangePropOnConnection(name, value) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [name]: value});
    };

    const handleJwtTokenChange = function handleJwtTokenChangeOnGcpCloudConnection(data = {jwtToken: '', jsonKeyFilename: ''}) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, ...data});
    };

    const handleInstanceCredentialsChange = function handleInstanceCredentialsChangeOnCloudConnectionForm(event) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [event.target.name]: event.target.value === "true"});
    };

  const onSubmit = async function createCloudConnection(event) {
    event.preventDefault();
    try {
      await props.saveCloudConnection(cloudConnection);resetErrors();
      toast.notify(({onClose}) =>
          <CustomAlert message={`Created cloud connection ${cloudConnection.name}.`} onClose={onClose}/>);
      history.push(routes.settings.path);
    } catch (error) {
      let tempErrors = convertErrorsToObject(error.response);
      setErrors(tempErrors);
    }
  };

  const handleCancel = function handleCancel() {
    if (isBlocking) {
      if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
        setIsBlocking(false);
        history.push(routes.settings.path);
      }
    } else {
      history.push(routes.settings.path);
    }
  };

  // noinspection MagicNumberJS
  return (
      <div className={classes.root}>
        <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
          <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.settings.path)}>
            <StyledBreadcrumbIcon theme={theme}/>
            Back to settings
          </StyledBreadcrumbLink>
        </StyledBreadcrumbs>
        <Card variant='outlined'>
          <CustomCardHeader
              title={routes.createCloudConnection.pageTitle(providerTitle())}
              titleAlign='center'
          />
          <CardContent>
            {provider === AWS &&
            <AwsCloudConnectionForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                    name={cloudConnection.name} setName={handleTextChange}
                                    notes={cloudConnection.notes} setNotes={handleTextChange}
                                    basePrefix={cloudConnection.basePrefix}
                                    setBasePrefix={handleTextChange}
                                    encryptionType={cloudConnection.encryptionType}
                                    setEncryptionType={handleTextChange}
                                    encryptionId={cloudConnection.encryptionId}
                                    setEncryptionId={handleTextChange}
                                    region={cloudConnection.region} setRegion={handleTextChange}
                                    useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                    setInstanceCredentials={handleInstanceCredentialsChange}
                                    keyId={cloudConnection.accessKeyId}
                                    setKeyId={handleTextChange}
                                    keySecret={cloudConnection.accessSecret}
                                    setKeySecret={handleTextChange}
                                    errors={errors}
                                    showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                    connectivity={initCloudConnectivity}
            />
            }
            {provider === AZURE &&
                <AzureCloudConnectionForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                          name={cloudConnection.name} setName={handleTextChange}
                                          notes={cloudConnection.notes} setNotes={handleTextChange}
                                          basePrefix={cloudConnection.basePrefix} setBasePrefix={setBasePrefix}
                                          useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                          setInstanceCredentials={handleInstanceCredentialsChange}
                                          connectionString={cloudConnection.connectionString}
                                          setConnectionString={handleTextChange}
                                          errors={errors}
                                          showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                          connectivity={initCloudConnectivity}
                />
            }
            {provider === GCP &&
            <GoogleCloudConnectionForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                       name={cloudConnection.name} setName={handleTextChange}
                                       notes={cloudConnection.notes} setNotes={handleTextChange}
                                       basePrefix={cloudConnection.basePrefix} setBasePrefix={handleTextChange}
                                       useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                       setInstanceCredentials={handleInstanceCredentialsChange}
                                       jwtToken={cloudConnection.jwtToken}
                                       setJwtToken={handleJwtTokenChange}
                                       errors={errors}
                                       showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                       connectivity={initCloudConnectivity}
            />
            }
          </CardContent>
        </Card>
      </div>
  );
}

CloudConnectionCreateComponent.propTypes = {
  saveCloudConnection: PropTypes.func,
};

export default CloudConnectionCreateComponent;
