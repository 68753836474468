import PageSection from "../common/PageSection";
import React, {useState, useEffect} from "react";
import InlineEditTextField from "../common/InlineEditTextField";
import api_routes from '../../util/api_routes';

import axios from 'axios';
import useSftpConfig from "../../api/useSftpConfig";
import Button from '@mui/material/Button';
import styled from "styled-components";

function SettingsCompSftpConfig(props) {

    const StyledButton = styled(Button)`
      text-transform: none;
    `;

    const {bannerText} = useSftpConfig();

    const [bannerTextValue, setBannerText] = useState(bannerText);

    useEffect(()=>{
        setBannerText(bannerText);
    }, [bannerText]);

    const save = async function save(bannerText){

        let response = await axios.put(api_routes.bannerText.endpoint, bannerText);
        setBannerText(() => response.data.bannerText);
    }

    return (
        <>
            <PageSection title='Banner Text'
                         subtitle='The server sends this banner text to the SFTP Client upon connection.'
                         action={<StyledButton variant='contained'
                                               disableElevation
                                         onClick={() => {
                                             save({bannerText: null})
                                         }}
                         >
                             Reset Banner Text to Default
                         </StyledButton>}
            />



            <InlineEditTextField name='bannerText'  labelVariant='body1'
                                 contentTypographyVariant='system'
                                 helperText={"The server sends this banner text to the SFTP Client upon connection."}
                                 value={bannerTextValue}
                                 autoComplete={"off"}
                                 save={(event, data) => save(data)}
                                 multiline showCount inputProps={{maxLength: 5000}}
                                 updateMessage='Saved'/>
        </>)
}

SettingsCompSftpConfig.propTypes = {};
SettingsCompSftpConfig.defaultProps = {};

export default SettingsCompSftpConfig;
