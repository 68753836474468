import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTheme} from '@mui/material/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {isEmpty} from '../../util/helpers';

const StyledWrapper = styled.div`
  width: ${props => {
    let width = props.width + '%';
    if (isEmpty(props.width)) {
        width = 'auto';
    }
    return width;
  }};
  position: relative;
  margin-top: ${props => props.theme.spacing(props.mt)};
  margin-right: ${props => props.theme.spacing(props.mr)};
  margin-left: ${props => props.theme.spacing(props.ml)};
  display: ${props => props.display};
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const StyledButton = styled(Button)`
  text-transform: ${props => props.buttontexttransform};
`;

// noinspection FunctionNamingConventionJS
function CircularProgressButton(props) {

    const theme = useTheme();

    // noinspection MagicNumberJS
    return (
        <StyledWrapper theme={theme} mt={props.mt} mr={props.mr} ml={props.ml} width={props.width}
                       display={props.display}>
            <StyledButton disableElevation fullWidth={props.fullWidth} variant={props.variant} color={props.color}
                          buttontexttransform={props.buttonTextTransform} size={props.size}
                          disabled={props.disabled || props.inProgress} type={props.type} style={props.style}
                          onClick={props.onClick} startIcon={props.startIcon} component={props.component}>
                {props.label}
            </StyledButton>
            {props.inProgress && <StyledCircularProgress size={24} color='secondary'/>}
        </StyledWrapper>);
}

CircularProgressButton.propTypes = {
    onClick: PropTypes.func,
    width: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    inProgress: PropTypes.bool,
    type: PropTypes.string,
    buttonTextTransform: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    component: PropTypes.any,
    mt: PropTypes.number, // margin-top
    mr: PropTypes.number, // margin-right
    ml: PropTypes.number, // margin-left
    fullWidth: PropTypes.bool,
    style: PropTypes.object,
    display: PropTypes.PropTypes.oneOf(['inline', 'block']),
};

CircularProgressButton.defaultProps = {
    type: 'button',
    buttonTextTransform: 'uppercase',
    mt: 1,
    mr: 0,
    ml: 0,
    variant: 'contained',
    color: 'primary',
    size: 'medium',
    fullWidth: true,
    display: 'block',
};

export default CircularProgressButton;
