import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {Container} from '@mui/material';
import 'toasted-notes/src/styles.css';

import routes from './util/routes';
import sftpgwTheme from './theme/sftpgateway';
import Authenticator from './container/auth/Authenticator';
import UserListContainer from './container/user/UserListContainer';
import UserCreateContainer from './container/user/UserCreateContainer';
import UserUpdateContainer from './container/user/UserUpdateContainer';
import Navigation from './component/layout/Navigation';
import ChangePasswordContainer from './container/user/ChangePasswordContainer';
import SettingsContainer from './container/settings/SettingsContainer';
import CloudConnectionCreateContainer from './container/settings/CloudConnectionCreateContainer';
import FolderListContainer from './container/folder/FolderListContainer';
import FolderCreateContainer from './container/folder/FolderCreateContainer';
import CloudConnectionUpdateContainer from './container/settings/CloudConnectionUpdateContainer';
import FolderUpdateContainer from './container/folder/FolderUpdateContainer';
import SystemInfoComponent from './component/SystemInfoComponent';
import FooterComponent from './component/FooterComponent';
import HelpSystemComponent from './component/HelpSystemComponent';

const initFeatures = () => {
    localStorage.setItem('flags', JSON.stringify([
        {name: 'azure', description: 'Displays the Azure UI features', active: true},
        {name: 'gcp', description: 'Displays the Google UI features', active: true},
    ]));
}
// noinspection FunctionNamingConventionJS
function App() {
    initFeatures();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={sftpgwTheme}>
                <CssBaseline/>
                <Authenticator>
                    <Router>
                        <Navigation/>
                        <Container>
                        <HelpSystemComponent>
                            <SystemInfoComponent/>
                            <div className={'content'}>
                                <Switch>
                                    <Route exact path={routes.users.path}>
                                        <UserListContainer/>
                                    </Route>
                                    <Route exact
                                           path={`${routes.users.path}${routes.createUser.path}`}>
                                        <UserCreateContainer/>
                                    </Route>
                                    <Route exact
                                           path={`${routes.users.path}/:userId${routes.updateUser.path}`}>
                                        <UserUpdateContainer/>
                                    </Route>
                                    <Route exact path={`${routes.profile.path}`}>
                                        <ChangePasswordContainer/>
                                    </Route>
                                    <Route exact path={`${routes.settings.path}`}>
                                        <SettingsContainer/>
                                    </Route>
                                    <Route exact
                                           path={`${routes.settings.path}${routes.cloudConnections.path}${routes.createCloudConnection.path}`}>
                                        <CloudConnectionCreateContainer/>
                                    </Route>
                                    <Route exact path={`${routes.settings.path}${routes.cloudConnections.path}/:cloudConnectionId${routes.updateCloudConnection.path}`}>
                                        <CloudConnectionUpdateContainer/>
                                    </Route>
                                    <Route exact path={routes.folders.path(0)}>
                                        <FolderListContainer/>
                                    </Route>
                                    <Route exact path={routes.folders.path()}>
                                        <FolderListContainer/>
                                    </Route>
                                    <Route exact path={`${routes.folders.path()}${routes.createFolder.path}`}>
                                        <FolderCreateContainer/>
                                    </Route>
                                    <Route exact path={`${routes.folders.path()}${routes.updateFolder.path}`}>
                                        <FolderUpdateContainer/>
                                    </Route>
                                    <Redirect from={'/'} to={routes.users.path}/>
                                </Switch>
                            </div>
                            <div className='footer'>
                                <FooterComponent/>
                            </div>
                        </HelpSystemComponent>
                        </Container>
                    </Router>
                </Authenticator>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
